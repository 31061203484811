import React, { Fragment, useContext, useEffect, useState } from "react";
import { NavLinksData } from "@/data";
import Link from "@/components/link";
import Img from "@/components/img";
import { MenuContext } from "@/context/menu-context";
import { graphql, useStaticQuery } from "gatsby"
import { useMatch } from "@reach/router"
import informCustomEvent from "../utils/analytics";

const isBrowser = typeof window !== "undefined"

const HeaderOne = ({showTagFilter=false}) => {
  const data = useStaticQuery(graphql`
    query {
      pharmacy {
        logo
        folLink
          prepaids {
          name
          type
        }

      }
    }
      `)
  const { logo, folLink, prepaids } = data.pharmacy;

  const [sticky, setSticky] = useState(false);
  const { menuStatus, updateMenuStatus, tagFilterStatus, setTagFilterStatus, contextTagFilter, setcontextTagFilter, contextTags } = useContext(MenuContext);
  const handleScroll = () => {
    if (isBrowser) {
         if (window.scrollY > (document?.getElementById('header').clientHeight ?? 70)) {
      setSticky(true);
    } else if (window.scrollY < 2*(document?.getElementById('header').clientHeight ?? 70)) {
      setSticky(false);
    }
    }
 
  };
  const handleMenuClick = (e) => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };

  const handleTagFilter = (clickedTag) => {
    if (!contextTagFilter.includes(clickedTag)) {
      setcontextTagFilter([...contextTagFilter, clickedTag])
    }
    else setcontextTagFilter(contextTagFilter.filter((tag) => tag !== clickedTag));
  }

  useEffect(() => {
    if (isBrowser) {
          window.addEventListener("scroll", handleScroll);

    }
    return () => {
      if (isBrowser) {
              window.removeEventListener("scroll", handleScroll);

      }
    };
  }, [sticky]);

  const NavbarItem = ( links, index) => {
    const isActive = !!useMatch(links.url)

    return (
      <li
        className={isActive ? "active-page" : ''}
        key={index}>
        <Link href={links.url}>{links.name}</Link>
        {undefined !== links.subItems ? (
          <ul className="sub-menu">
            {links.subItems.map((subLinks, index) => (
              <li key={index}>
                <Link href={subLinks.url}>{subLinks.name}</Link>
              </li>
            ))}
          </ul>
        ) : null}
      </li>
    );
  }

  return (
    <Fragment>


      <div id='header'
        className={`main_menu_area ${
          true === sticky
            ? " stricky stricky-fixed slideInDown animated"
            : " stricky slideIn animated"
        }`}
      >
        <div className="container">
          <div className="main_menu_area__left">
            <span className="mobile-menu__toggler" role='button' tabIndex={0} onClick={handleMenuClick} onKeyDown={handleMenuClick}> 
              <i className="fa fa-lg fa-bars"></i>
            </span>
            <Link href="/">
              <Img src={logo} className='py-2 showOnMobile' width="110" alt="Awesome Image" layout="fixed" />
              <Img src={logo} className='showOnDesktop' width="150" alt="Awesome Image" layout="fixed" />
            </Link>
            <a href={folLink}
              onClick={() => informCustomEvent('clickComprasButton', { display: 'mobile' })}
            >
              <span className="mobile-menu__toggler">
                <i className="fa fa-lg fa-shopping-cart">
                </i>
              </span>
            </a>


          </div>
          <div className="main_menu_area__right">
            <nav className="main_menu_area__menu">
              <ul className="nav navbar-nav navigation-box">
                {NavLinksData.map((links, index) => 
                  links.name !== 'Coberturas médicas' || prepaids?.length > 0 ?
                  NavbarItem(links, index) : null
                )}
              </ul>
            </nav>
            <a href={folLink}
              className="btn-yellow"
              onClick={() => informCustomEvent('clickComprasButton', { display: 'desktop' })}
            >
              COMPRAS ONLINE
            </a>
          </div>
        </div>
        {showTagFilter &&
          <>
           <div className="d-flex justify-content-center showOnMobile tag-filter">
            <button
              className="tag-filter my-2"
              onClick={()=> setTagFilterStatus(!tagFilterStatus)}
            >
              <i className="mx-2 fa fa-lg fa-sliders"></i> Filtrar etiquetas
            </button>
          </div>
          {tagFilterStatus &&
            <div style={{
            position: 'absolute',
            // top: document.getElementById('header').clientHeight,
            left: 0,
            width: '100%',
            backgroundColor: 'var(--primaryColor)',
          }}
            className='animated fadeInLeft'
          >
            <div className="blog_right_box">
                <div className="tagcloud">
                  <div className="d-flex">
                      <h2>Etiquetas</h2>
                  <span className="ms-auto" onClick={() => setTagFilterStatus(!tagFilterStatus)} onKeyDown={()=>null} role='button' tabIndex={0}>
                    <i className="fa fa-times"></i>
                  </span>
                  </div>
                
                <ul>
                  {contextTags?.map((tag) =>
                    <li key={`tag-${tag.name}`}>
                      <span onKeyDown={() => null} role='button' tabIndex={0} className={`border rounded-pill px-3 mx-1 ${contextTagFilter?.includes(tag.name) ? 'active' : ''}`} onClick={setcontextTagFilter ? () => handleTagFilter(tag.name) : isBrowser ? () => {  window.location.href = `/blog?searchTag=${tag.name}`  } : ()=>null }>{tag.name}</span>
                    </li>
                  )}


                </ul>
              </div>
            </div>
          </div>}
          </>
        }
      </div>
      {sticky &&
        <div
          className="showOnMobile"
          style={{ height: isBrowser ? document.getElementById('header').clientHeight : '' }}>
      </div>
      }
    </Fragment>
  );
};

export default HeaderOne;
