import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useState, useEffect } from "react";
import lightenDarkenColor from "../utils/litghtenDarkenColor";


export default function Prepaids() {
  
  const data = useStaticQuery(graphql`
    query {
      pharmacy {
        name
        colors{
          primaryColor
          secondaryColor
        }
        prepaidsBannerImg
        prepaids {
          name
          type
        }
      }
    }
  `)
  const vw = 1200
  const { prepaids, colors } = data.pharmacy;
  const [renderedPrepaids, setRenderedPrepaids] = useState(prepaids);
  const [searchTerm, setsearchTerm] = useState("");
  const [maxLength, setMaxLength] = useState(renderedPrepaids.length);

  useEffect(() => {
    setRenderedPrepaids(prepaids.filter(prepaid => prepaid.name.toLowerCase().includes(searchTerm.toLowerCase()) || searchTerm === ""))
  }, [searchTerm, prepaids])

  const handleSearchTermChange = (event) => {
    setsearchTerm(event.target.value)
  };

  const prepaidTypes = ["OSO", "PRE", "ART"]
  const prepaidNames = {
    "OSO": "Obras Sociales",
    "PRE": "Prepagas",
    "ART": "ART"
  }

  useEffect(() => {
    let currentMaxLength = 0;
    for (let prepaidType of prepaidTypes) {
      const rplen = renderedPrepaids.filter(
        prepaid => {
          return prepaidType === prepaid.type
        }
      ).length
      currentMaxLength = rplen > currentMaxLength ? rplen : currentMaxLength;
    }
    setMaxLength(currentMaxLength)
  }, [renderedPrepaids, prepaidTypes])

  const fillArray = (arr, vw) => {
    const fillerArray =  vw < 992 ? [] : Array(Math.abs(maxLength - arr.length));
    return [...arr, ...fillerArray]
  };

  const theadStyle = {
    backgroundColor: colors.secondaryColor,
    color: "white"
  };
  
  return (

      <div className="content">
        <div className="container" style={{ paddingTop: "6rem", paddingBottom: "6rem" }}>
          <div className="searchBar">

          <div className="d-flex flex-wrap prepaids-header py-1" style={{backgroundColor: colors.primaryColor}}>
             <span className="ms-3 my-auto">Coberturas Médicas</span>
              <div className="ms-auto d-flex me-3" >
                <i className="fa fa-search m-auto me-2" />
                <input
                  type="text"
                  className="my-2"
                  placeholder="Buscar mi cobertura médica"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                onChange={handleSearchTermChange}
                style={{width: '250px'}}
                />
              </div>
          </div>
          <div className="row" style={{ padding: "1rem", paddingTop: 0, paddingBottom: 0, marginTop: vw < 992 ? "2.9rem" : "auto" }}>
            {prepaidTypes.map((prepaidType, idx) => {
              const colColor = idx % 2 === 0 || vw < 992 ? lightenDarkenColor(colors.primaryColor, 50) : lightenDarkenColor(colors.primaryColor, 70);
              return (
                <div key={idx} className="col-md-4" style={{ padding: 0, marginBottom: 0 }}>
                  <table className="table">
                    <thead style={theadStyle}>
                      <tr>
                        <th>{prepaidNames[prepaidType]}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fillArray(renderedPrepaids.filter(
                        prepaid => {
                          return prepaidType === prepaid.type
                        }
                      ), vw).map((prepaid, idx) => {
                        return (
                          <tr key={idx}>
                            <td style={{ height: "2.6rem", position: "relative" }}>
                              <div style={{
                                height: "100%",
                                width: "100%",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                backgroundColor: colColor,
                                opacity: 0.5
                              }}></div>
                              <div style={{
                                height: "2.6rem",
                                lineHeight: "2.6rem",
                                fontWeight: "bolder",
                              }}>{prepaid ? prepaid.name : ""}</div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              )
            })}
          </div>

          </div>
        </div>
      </div>
  )
}
