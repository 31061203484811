import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import Prepaids from "../components/prepagas";
import servicesImage from '../images/prepagas_desktop.jpg'

const Service = () => {

  return (
    <MenuContextProvider>
      <Layout PageTitle="Coberturas Médicas">
        <HeaderOne />
        <PageHeader title="Coberturas Médicas" name="Service" altImage={servicesImage} />
      <Prepaids />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default Service;
