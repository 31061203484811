import React from "react";

const PageHeader = ({ title='', name, altImage='' }) => {

  return (
      <section className={`breadcrumb_section text-center section_padding`}
      style={{
        backgroundImage: `url(${altImage})`,
        backgroundPosition: 'center'
      }}
    >
        <h1>{title}</h1>
      <div className="gallery_overlay showOnMobile"></div>
      </section>  
  );
};

export default PageHeader;
